import React, { useContext, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../../../app/store/AuthContext';
import { useLocation } from 'react-router-dom';
import { Alert } from '@mui/material';

export const LoginButtonGoogle = (_props: any) => {
    const value = useContext(AuthContext);
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const onSuccess = async (credentialResponse: any) => {
        setErrorMessage(null); // Reset previous error

        try {
            const idToken = credentialResponse.credential; // ID Token from Google

            // Send ID Token to backend
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idToken }),
            });

            const result = await response.json();

            if (result.login === true) {
                value?.setAuthentification({
                    isLoggedIn: result.login,
                    apiToken: result.apiToken,
                    isManager: result.isManager,
                    role: result.role,
                });

                if (['/myaccounts', '/', '/login', '/logout'].includes(location.pathname)) {
                    if (['1', '4', '5', '7'].includes(result.role) && result.isManager !== false) {
                        window.location.href = "/myaccounts";
                    } else {
                        window.location.href = "/tools";
                    }
                } else {
                    window.location.href = location.pathname;
                }
            } else {
                setErrorMessage(result.error || "Login failed. Please try again.");
            }
        } catch (error) {
            setErrorMessage("An error occurred while logging in. Please try again.");
        }
    };

    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {errorMessage && (
                <Alert severity="error" sx={{ marginBottom: 2, textAlign: "center", fontSize: "14px" }}>
                    {errorMessage}
                </Alert>
            )}
            <div style={{ width: "100%" }}>
              <GoogleLogin
                  onSuccess={onSuccess}
                  onError={() => setErrorMessage("Login failed. Please try again.")}
                  locale="en" 
              />
            </div>
        </div>
    );
};


// OLD VERSION BEFORE 26.01.25 - uses old LoginButtonGoogle component
// import React, { useContext } from 'react';
// import {GoogleLogin} from 'react-google-login';
// import { AuthContext } from '../../../app/store/AuthContext';
// import { useLocation } from 'react-router-dom';

// export const LoginButtonGoogle = (_props:any) => {
//     const value = useContext(AuthContext);
//     const auth = value?.auth.isLoggedIn;
//     const role = value?.auth.role;
//     const isManager = value?.auth.isManager;
//     const location = useLocation();
    
//     const onSuccess = (res: any) => {
//         const loginData = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(res)
//         }
//         fetch('/api/login', loginData).then(response => response.json()).then(function(result) {
//             if(result.login === true){
//                 value?.setAuthentification({
//                   isLoggedIn:result.login,
//                   apiToken: result.apiToken,
//                   isManager: result.isManager,
//                   role: result.role
//                 });
//                 if(location.pathname === '/myaccounts' || location.pathname === '/' || location.pathname === '/login' || location.pathname === '/logout' ){
//                   if((result.role === '1' || result.role === '4' || result.role === '5' || result.role === '7') && result.isManager !== false){
//                     window.location.href ="/myaccounts";
//                   }
//                   else{
//                     window.location.href = "/tools";
//                   }
//                 }
//                 else{
//                   window.location.href  = location.pathname;
//                 }
      
//               }
//         }
//     )
//     .catch(function(error) {});
//     }

//   return (
//     <div>
//       {/* <GoogleLogin
//         clientId={_props.clientId}
//         buttonText="Login"
//         onSuccess={onSuccess}
//         cookiePolicy={'single_host_origin'}
//         style={{marginTop: '100px'}}
//       /> */}
//       {React.createElement(GoogleLogin as any, {
//         clientId: _props.clientId,
//         buttonText: "Login",
//         onSuccess: onSuccess,
//         cookiePolicy: 'single_host_origin',
//         style: {marginTop: '100px'}
//       })}
//     </div>
//   );
// }

