import React, { useContext, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../../../app/store/AuthContext';
import { useLocation } from 'react-router-dom';
import { Alert, Button } from '@mui/material';

export const LoginButtonGoogle = (_props: any) => {
    const value = useContext(AuthContext);
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const onSuccess = async (credentialResponse: any) => {
      //     setErrorMessage(null); // Reset previous error
    }
  
    // const login = useGoogleLogin({
    //   onSuccess: tokenResponse => console.log(tokenResponse),
    // });

    // const onSuccess = async (credentialResponse: any) => {
    //     setErrorMessage(null); // Reset previous error

    const login = useGoogleLogin({
      onSuccess: async (tokenResponse) => {
          const accessToken = tokenResponse.access_token; // Get access token instead of ID token
          setErrorMessage(null);

        try {
            // const idToken = credentialResponse.credential; // ID Token from Google

            // // Send ID Token to backend
            // const response = await fetch('/api/login', {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ idToken }),
            // });

            const accessToken = tokenResponse.access_token;
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ accessToken }),  // Change this
            });

            const result = await response.json();

            if (result.login === true) {
                value?.setAuthentification({
                    isLoggedIn: result.login,
                    apiToken: result.apiToken,
                    isManager: result.isManager,
                    role: result.role,
                });

                if (['/myaccounts', '/', '/login', '/logout'].includes(location.pathname)) {
                    if (['1', '4', '5', '7'].includes(result.role) && result.isManager !== false) {
                        window.location.href = "/myaccounts";
                    } else {
                        window.location.href = "/tools";
                    }
                } else {
                    window.location.href = location.pathname;
                }
              } else {
                setErrorMessage(result.error || "Login failed. Please try again.");
            }
        } catch (error) {
            setErrorMessage("An error occurred while logging in. Please try again.");
        }
    },
    onError: () => setErrorMessage("Login failed. Please try again."),
    scope: "openid email profile", // Ensure correct scopes
});


const [hover, setHover] = useState(false);

    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "left" }}>
            {errorMessage && (
                <Alert severity="error" sx={{ marginBottom: 2, textAlign: "center", fontSize: "14px" }}>
                    {errorMessage}
                </Alert>
            )}
            {/* <div style={{ width: "100%" }}> */}
            <div>
              {/* <GoogleLogin
                  onSuccess={onSuccess}
                  onError={() => setErrorMessage("Login failed. Please try again.")}
                  locale="en" 
              /> */}
              <Button color="primary" onClick={() => login()}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={{ width: "100%", 
                  color:'#3c4043',
                  border: hover ? '1px solid #D2E2FC' : '1px solid #dadce0',
                  backgroundColor: hover ? '#F7FAFE' : 'white',
                  fontFamily: '"Google Sans", arial, sans-serif', fontSize: '14px', textTransform:'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // padding: '10px 0',
                  position: 'relative',
                  cursor: 'pointer',
                  transition: 'background-color 0.2s ease, border-color 0.2s ease'
                }}>
                  <div style={{
                    position: 'absolute', 
                    left: '15px',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ width: '18px', height: '18px' }}>
                      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  Sign in with Google
              </Button>
            </div>
        </div>
    );
};


// OLD VERSION BEFORE 26.01.25 - uses old LoginButtonGoogle component
// import React, { useContext } from 'react';
// import {GoogleLogin} from 'react-google-login';
// import { AuthContext } from '../../../app/store/AuthContext';
// import { useLocation } from 'react-router-dom';

// export const LoginButtonGoogle = (_props:any) => {
//     const value = useContext(AuthContext);
//     const auth = value?.auth.isLoggedIn;
//     const role = value?.auth.role;
//     const isManager = value?.auth.isManager;
//     const location = useLocation();
    
//     const onSuccess = (res: any) => {
//         const loginData = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(res)
//         }
//         fetch('/api/login', loginData).then(response => response.json()).then(function(result) {
//             if(result.login === true){
//                 value?.setAuthentification({
//                   isLoggedIn:result.login,
//                   apiToken: result.apiToken,
//                   isManager: result.isManager,
//                   role: result.role
//                 });
//                 if(location.pathname === '/myaccounts' || location.pathname === '/' || location.pathname === '/login' || location.pathname === '/logout' ){
//                   if((result.role === '1' || result.role === '4' || result.role === '5' || result.role === '7') && result.isManager !== false){
//                     window.location.href ="/myaccounts";
//                   }
//                   else{
//                     window.location.href = "/tools";
//                   }
//                 }
//                 else{
//                   window.location.href  = location.pathname;
//                 }
      
//               }
//         }
//     )
//     .catch(function(error) {});
//     }

//   return (
//     <div>
//       {/* <GoogleLogin
//         clientId={_props.clientId}
//         buttonText="Login"
//         onSuccess={onSuccess}
//         cookiePolicy={'single_host_origin'}
//         style={{marginTop: '100px'}}
//       /> */}
//       {React.createElement(GoogleLogin as any, {
//         clientId: _props.clientId,
//         buttonText: "Login",
//         onSuccess: onSuccess,
//         cookiePolicy: 'single_host_origin',
//         style: {marginTop: '100px'}
//       })}
//     </div>
//   );
// }

